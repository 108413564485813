import React from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { configApi, playerApi, nflGameApi, userApi } from './adapters/APIExporter';
import { authenticateUrl, getUserInfo } from './adapters/ServiceAPI';
import './App.css';
import AboutUs from './components/body/about_us';
import DraftRoom from './components/body/draft/DraftRoom';
import DraftHistory from './components/body/draft/DraftHistory';
import AuctionRoom from './components/body/auction/AuctionRoom';
import AuctionHistory from './components/body/auction/AuctionHistory';
import LeagueHistory from './components/body/league/LeagueHistory';
import TradeBuilder from './components/body/trade/TradeBuilder';
import TradeReview from './components/body/trade/TradeReview';
import TradesOverview from './components/body/trade/TradesOverview';
import DropForTrade from './components/body/trade/DropForTrade';
import CompletedTradeViewer from './components/body/trade/CompletedTradeViewer';
import Home from './components/body/home';
import CommissionerTools from './components/body/league/commish/CommissionerTools';
import CreateLeague from './components/body/league/CreateLeague';
import LeagueHome from './components/body/league/LeagueHome';
import Profile from './components/body/profile/index';
import TeamSettings from './components/body/team/TeamSettings';
import LeagueView from './components/nav/side-nav/LeagueView';
import MidSeasonExtensionsView from './components/body/contract/MidSeasonExtensionsView'
import TeamPlayerClaimsView from './components/body/playerClaims/TeamPlayerClaimsView';
import DropForWaiverClaim from './components/body/playerClaims/DropForWaiverClaim';
import DropForFaabClaim from './components/body/playerClaims/DropForFaabClaim';
import DropForPoachClaim from './components/body/playerClaims/DropForPoachClaim';
import TeamPlayerPoachView from './components/body/playerClaims/TeamPlayerPoachView';
import DropForAdd from './components/body/playerClaims/DropForAdd';
import PlayerAcquisitionEventView from './components/body/playerClaims/PlayerAcquisitionEventView';
import ResponsiveAppBar from './components/nav/top-nav';
import PlayerSearch from './components/search';
import ContractsView from './components/body/contract/ContractsView';
import LeagueSchedule from './components/body/league/LeagueSchedule';
import { setPlayerDataLoad } from './slices/playerDataLoad';
import { setNflGameDataLoad } from './slices/nflGameDataLoad';
import { setUser } from './slices/user';

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { useAppSelector } from './app/hooks';
import TeamView from './components/body/team/TeamView';
import LeagueDraftPicks from './components/body/league/LeagueDraftPicks';
import LeagueTransactions from './components/body/league/LeagueTransactions';
import MatchupView from './components/body/games/MatchupView';
import { setGlobalConfigDataLoad } from './slices/globalConfigDataLoad';
import LeagueMatchupView from './components/body/games/LeagueMatchupView';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const PrivateRoute = ({ children }) => {
  const [cookies, setCookie] = useCookies(['capsized']);

  const user = useAppSelector((state) => state.user );
  if (!user.id) {
  	setCookie('customNav', window.location.pathname, { path: '/' });
	  window.location.assign(authenticateUrl);
  }
  return children;
}

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCDgdEMbZn0mfZ96qiDBX4bBAQZckYX1o4",
  authDomain: "capsized-3f3d2.firebaseapp.com",
  projectId: "capsized-3f3d2",
  storageBucket: "capsized-3f3d2.appspot.com",
  messagingSenderId: "525609069180",
  appId: "1:525609069180:web:0c5cefdb3c1429d8b1f7f9",
  measurementId: "G-KSJKQ699SL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

const App = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['capsized']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUserFetchingDone, setIsUserFetchingDone] = React.useState(false)
  const [isPlayerDataFetchingDone, setIsPlayerDataFetchingDone] = React.useState(false)

  React.useEffect(() => {
	  getUserInfo().then((resp) => {
	    let newUser = resp.data
		  dispatch(setUser(newUser));
		  if (window.location.pathname !== '/profile') {
		  	if (newUser && !newUser.username && window.location.pathname !== '/profile') {
		  		navigate('/profile', {state : {from : window.location.pathname}});
		    } else {
		  	  const nav = cookies.customNav;
		      if (nav) {
				  removeCookie('customNav', { path: '/' });
		    	navigate(nav);
		      }
		    }
		  }
	    setIsUserFetchingDone(true);
	  }).catch((e) => {
		  dispatch(setUser(null));
      console.log("Setting fetching user (Error)")
		  setIsUserFetchingDone(true);
	  })
  }, [])

  React.useEffect(() => {
    playerApi.loadPlayersMin().then((resp) => {
      let playerData = resp.data
      console.log("Server Data Response: ", playerData)
      playerData.forEach(team => {
        const {players, ...transformedTeam} = team;
        team.players.forEach(player => {
          player.nfl_team = transformedTeam;
          const ageDate = new Date(new Date() - Date.parse(player.DOB));
          player.ageYears = Math.abs(ageDate.getUTCFullYear() - 1970);
        })
      })
      dispatch(setPlayerDataLoad(playerData))
      setIsPlayerDataFetchingDone(true);
    }).catch((e) => {
      dispatch(setPlayerDataLoad(null));
      console.log("Setting fetching playerData (Error)")
      setIsPlayerDataFetchingDone(true);
    });
  }, [])


  
  React.useEffect(() => {
    configApi.getGlobalConfig().then((resp) => {
      let globalConfig = resp.data
      console.log("Config Response: ", globalConfig)
      dispatch(setGlobalConfigDataLoad(globalConfig))
    }).catch((e) => {
      dispatch(setGlobalConfigDataLoad(null));
      console.log("Setting fetching globalData (Error)")
    });
  }, [])
  
  const globalConfig = useAppSelector((state) => state.globalConfigDataLoad)
  
  React.useEffect(() => {
    if (globalConfig && globalConfig.currentSeason && globalConfig.currentWeek) {
      nflGameApi.getGames(globalConfig.currentSeason, globalConfig.currentWeek).then(response => {
        dispatch(setNflGameDataLoad(response.data));
      })
    }
  }, [globalConfig])

  return (
    <div className="App">
      <header className="navbar">
        <meta name="referrer" content="no-referrer" />
				<ResponsiveAppBar />
      </header>
      <div className="App-body">
      	{ isUserFetchingDone && isPlayerDataFetchingDone && children }
      </div>
    </div>
  );
}

export default class AppRouter extends React.Component {
	render() {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
    getToken(messaging, {
          serviceWorkerRegistration: registration,
          vapidKey: 'BESWS85gKmuH2P2anLDQi319ZAWINVPns2u0BfMFk9jmlOhrxfOMkfhSQ1zAMBTnsl5OdNJUGev75Znxfttz51Y' }).then((currentToken) => {
        if (currentToken) {
          console.log("Current token: " + currentToken);
          userApi.enableNotifications(currentToken);
          return currentToken
        } else {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
            }
          });
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
	    return (
		    <Router>
		      <Routes>
			      <Route path="/" element={<App><Home /></App>} />
			      <Route path="/profile" element={<App><PrivateRoute><Profile /></PrivateRoute></App>} />
			      <Route path="/createLeague" element={<App><PrivateRoute><CreateLeague /></PrivateRoute></App>} />
			      <Route path="/league/:leagueId" element={<App><PrivateRoute><LeagueView><LeagueHome /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/commish" element={<App><PrivateRoute><LeagueView><CommissionerTools /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/players" element={<App><PrivateRoute><LeagueView><PlayerSearch /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/contracts" element={<App><PrivateRoute><LeagueView><ContractsView /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/schedule" element={<App><PrivateRoute><LeagueView><LeagueSchedule /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/picks" element={<App><PrivateRoute><LeagueView><LeagueDraftPicks /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/transactions" element={<App><PrivateRoute><LeagueView><LeagueTransactions /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId" element={<App><PrivateRoute><LeagueView><TeamView /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/settings" element={<App><PrivateRoute><LeagueView><TeamSettings /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/trades" element={<App><PrivateRoute><LeagueView><TradesOverview /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/trade" element={<App><PrivateRoute><LeagueView><TradeBuilder /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/trade/:tradeId" element={<App><PrivateRoute><LeagueView><TradeReview /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/trade/:tradeId/edit" element={<App><PrivateRoute><LeagueView><TradeBuilder /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/trade/:tradeId/counter" element={<App><PrivateRoute><LeagueView><TradeBuilder /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/trade/:tradeId/drop" element={<App><PrivateRoute><LeagueView><DropForTrade /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/tradeHistory/:tradeId" element={<App><PrivateRoute><LeagueView><CompletedTradeViewer /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/draftHistory" element={<App><PrivateRoute><LeagueView><DraftHistory /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/auctionHistory" element={<App><PrivateRoute><LeagueView><AuctionHistory /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/history" element={<App><PrivateRoute><LeagueViewIncludeTeams><LeagueHistory /></LeagueViewIncludeTeams></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/playerClaims" element={<App><PrivateRoute><LeagueView><TeamPlayerClaimsView /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/midSeasonExtensions" element={<App><PrivateRoute><LeagueView><MidSeasonExtensionsView /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/waivers/:playerId" element={<App><PrivateRoute><LeagueView><DropForWaiverClaim /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/faab/:playerId" element={<App><PrivateRoute><LeagueView><DropForFaabClaim /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/quickSign/:playerId" element={<App><PrivateRoute><LeagueView><DropForAdd /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/poach/:playerId" element={<App><PrivateRoute><LeagueView><DropForPoachClaim /></LeagueView></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/team/:teamId/poachOffers" element={<App><PrivateRoute><LeagueView><TeamPlayerPoachView /></LeagueView></PrivateRoute></App>} />
			      
            {/* Matchups */}
            <Route path="/league/:leagueId/matchup/:week/:season" element={<App><PrivateRoute><LeagueViewIncludeTeams><MatchupView /></LeagueViewIncludeTeams></PrivateRoute></App>} />
            <Route path="/league/:leagueId/matchup/id/:id" element={<App><PrivateRoute><LeagueViewIncludeTeams><MatchupView /></LeagueViewIncludeTeams></PrivateRoute></App>} />
			      <Route path="/league/:leagueId/matchup" element={<App><PrivateRoute><LeagueViewIncludeTeams><MatchupView /></LeagueViewIncludeTeams></PrivateRoute></App>} />
			      
            {/* League View */}
            <Route path="/league/:leagueId/week/:week/:season" element={<App><PrivateRoute><LeagueViewIncludeTeams><LeagueMatchupView /></LeagueViewIncludeTeams></PrivateRoute></App>} />
            <Route path="/league/:leagueId/week" element={<App><PrivateRoute><LeagueViewIncludeTeams><LeagueMatchupView /></LeagueViewIncludeTeams></PrivateRoute></App>} />
            
			      
            <Route path="/league/:leagueId/playerAcquisitionEvent/:eventId" element={<App><PrivateRoute><LeagueView><PlayerAcquisitionEventView /></LeagueView></PrivateRoute></App>} />
			      <Route path="/draft/:leagueId" element={<App><PrivateRoute><DraftRoom /></PrivateRoute></App>} />
			      <Route path="/auction/:leagueId" element={<App><PrivateRoute><AuctionRoom /></PrivateRoute></App>} />
            <Route path="/about_us" element={<App><AboutUs /></App>} />		
	      
			    </Routes>
		    </Router>
		)
	}

}

function LeagueViewIncludeTeams({ children }) {
  const includeRosters = true;
  return <LeagueView includeRosters={includeRosters}>{children}</LeagueView>
}
